<template>
    <v-container class="pa-0" fluid>
        <v-progress-linear v-if="loading" />
        <v-row no-gutters>
            <v-col
                cols="4"
                xl="1"
                lg="1"
                md="2"
                sm="1"
                class="d-flex align-center"
            >
                <span>Tags</span>
            </v-col>
            <v-col cols="8" class="d-flex align-center flex-wrap">
                <v-card
                    v-for="(tag, index) in tags"
                    :key="index"
                    flat
                    class="mr-1 ml-1"
                >
                    <v-chip>
                        <span>
                            {{ tag }}
                        </span>
                        <v-btn icon small class="ml-2" @click="deleteTag(tag)">
                            <v-icon size="20">mdi-close-circle</v-icon>
                        </v-btn>
                    </v-chip>
                </v-card>
                <v-btn
                    @click="openDialogTags"
                    class="my-2 ml-2"
                    depressed
                    fab
                    x-small
                    color="primary"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-dialog
                :retain-focus="false"
                v-model="addDialog"
                persistent
                max-width="400px"
            >
                <v-card>
                    <v-card-title class="text-h5">Add Tags</v-card-title>
                    <v-card-text>
                        <div class="tag-input align-center">
                            <v-text-field
                                @keydown.enter="addTag"
                                @keydown.delete="removeLastTag"
                                dense
                                hide-details
                                v-model="tag"
                                flat
                                label="Enter a Tag"
                            >
                            </v-text-field>
                            <div class="my-2">
                                <v-chip
                                    @click:close="removeTag(index)"
                                    v-for="(tag, index) in tempTags"
                                    :key="index"
                                    close
                                    class="ma-1"
                                >
                                    {{ tag }}
                                </v-chip>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="secondary" @click="closeDialog">
                            Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="saveTags"
                            text
                            color="error"
                            :loading="loading"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/services/api'
//import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'CompanyTags',
    props: {
        setting: Object,
    },
    data() {
        return {
            tags: [],
            tempTags: [],
            tag: '',
            rules: {
                required: v => !!v || 'Required',
                value: v => !v || v.size > 0 || 'Required',
            },
            addDialog: false,
            loading: false,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
        }
    },
    watch: {
        value: function() {
            this.tags = []
        },
    },
    mounted() {
        this.tags = this.setting.tags === undefined ? [] : this.setting.tags
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async deleteTag(tag) {
            try {
                this.loading = true
                this.errorMsg = ''
                this.tags = this.tags.filter(t => t !== tag)
                await API.updateCompany(this.companyId, {
                    settingId: this.setting.id,
                    tags: this.tags,
                })
                this.setting.tags = [...this.tags]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveTags() {
            try {
                this.loading = true
                this.errorMsg = null
                this.tags = [...this.tags, ...this.tempTags]
                await API.updateCompany(this.companyId, {
                    settingId: this.setting.id,
                    tags: this.tags,
                })
                this.setting.tags = [...this.tempTags]
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDialog() {
            this.tempTags = []
            this.addDialog = false
        },
        openDialogTags() {
            this.addDialog = true
        },
        addTag(event) {
            event.preventDefault()
            var val = event.target.value?.trim().toLowerCase()
            if (val?.length > 0) {
                this.tempTags.push(val)
                event.target.value = ''
                this.tag = ''
            }
        },
        removeTag(index) {
            this.tempTags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
                this.removeTag(this.tempTags.length - 1)
            }
        },
    },
}
</script>

<style></style>
